.homeCategoryTagsWrapper {
	gap: 5px;
	display: flex;
	padding-bottom: 12px;
}

.homeCategoryTag {
	font-size: 18px;
	font-weight: 300;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin: 16px;
}

.sider {
  border-radius: 0 8px 8px 0;
  margin-top: 12px;
  margin-bottom: 12px;
  overflow: hidden;
}
